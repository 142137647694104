/* eslint-disable @typescript-eslint/no-explicit-any */
import { taskEither } from 'fp-ts';
import { TaskEither } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { omit, prop } from 'rambda';
import { ReactLeft } from 'utils/uiStates/uiStates';
import { listModelsTE } from 'api/models/listModels';
import { listWorkflowsTE } from 'api/workflows';
// import { listModulesTE } from 'api/modules/listModules';
import { errorToReactLeft } from 'utils/fp';

export type SessionTokenProp = {
  sessionToken: string;
};

export function excludeProps<T>(list: T[], props: (keyof T)[] = []): T[] {
  return list.map((item) => omit(props as string[], item)) as T[];
}

export function getMinimalModels(models: CF.API.Models.Model[]) {
  return excludeProps(models, ['model_version', 'notes']);
}

export function getMinimalWorkflows(workflows: CF.API.Workflows.Workflow[]) {
  return excludeProps(workflows, ['nodes', 'notes']);
}

export const featuredApiEffects = {
  listFeaturedModelsTE: (props?: SessionTokenProp): TaskEither<ReactLeft, CF.API.Models.Model[]> => {
    return pipe(
      listModelsTE(
        { params: 'featured_only=true&additional_fields=stars&additional_fields=presets', sessionToken: props && prop('sessionToken')(props) },
        errorToReactLeft,
      ),
      taskEither.map(prop('models')),
    );
  },
  listFeaturedWorkflowsTE: (props?: SessionTokenProp): TaskEither<ReactLeft, CF.API.Workflows.Workflow[]> => {
    return pipe(
      listWorkflowsTE({ params: 'featured_only=true&additional_fields=stars', sessionToken: props && prop('sessionToken')(props) }, errorToReactLeft),
      taskEither.map(prop('workflows')),
      taskEither.map(getMinimalWorkflows),
    );
  },
  // listFeaturedModulesTE: (props?: SessionTokenProp): TaskEither<ReactLeft, CF.API.Modules.Module[]> => {
  //   return pipe(
  //     listModulesTE({ params: 'featured_only=true&additional_fields=stars',
  //     sessionToken: props && prop('sessionToken')(props) }, errorToReactLeft),
  //     taskEither.map((resp) => resp.modules),
  //   );
  // },
};
