import { cx, LinariaClassName } from '@linaria/core';
import { cardContainer } from './Card.styles';

type Props = {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  body: string | React.ReactNode;
  footer: string | React.ReactNode;
  badge?: string | React.ReactNode;
  badgeClassName?: string | LinariaClassName;
  cardClassName?: string;
};

export const Card: React.FC<Props> = ({ icon, title, body, footer, badge, badgeClassName, cardClassName }) => {
  return (
    <div className={cx(cardContainer, cardClassName)}>
      <div className="card">
        {badge && <div className={cx('badge', badgeClassName)}>{badge}</div>}
        <div className="header">
          {icon}
          <h3 className="title">{title}</h3>
        </div>
        <div className="body">{body}</div>
      </div>
      <div className="footer">{footer}</div>
    </div>
  );
};
