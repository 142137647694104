import { css, cx } from '@linaria/core';
import { breakpoints } from 'styles/utils';
type Props = {
  className?: string;
};
export const HorizontalLayout: React.FC<Props> = ({
  children,
  className
}) => {
  return <div className={cx(layoutStyles, className)}>{children}</div>;
};
const layoutStyles = "odules__Home__layout__HorizontalLayout_tsx-layoutStyles-lhg2hcr";

require("./HorizontalLayout.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./HorizontalLayout.tsx");