/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'components/Link';
import { JiraButton, JiraLinks } from 'components/Jira';
import { cx } from '@linaria/core';
import { homePageFooterLink, homePageFooterLinks, homePageFooterWrapper } from '../HomePageLayout.styles';

// TODO: ADD THE LINKS BEFORE PUSHING TO MASTER
export function HomePageFooter({ footerClassName }: { footerClassName?: string }) {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={cx(homePageFooterWrapper, footerClassName)}>
      <span className="legal">&copy; {currentYear} Clarifai. All rights reserved.</span>
      <ul className={homePageFooterLinks}>
        <li>
          <Link rel="noreferrer" target="_blank" href="https://docs.clarifai.com/" className={homePageFooterLink}>
            Clarifai Docs
          </Link>
        </li>
        <li>
          <JiraButton collectorId={JiraLinks.bugReport.collectorId} clickTargetSelector={JiraLinks.bugReport.clickTargetSelector}>
            <a className={homePageFooterLink}>Report An Issue</a>
          </JiraButton>
        </li>
        <li>
          <JiraButton collectorId={JiraLinks.sendFeedback.collectorId} clickTargetSelector={JiraLinks.sendFeedback.clickTargetSelector}>
            <a className={homePageFooterLink}>Product Feedback</a>
          </JiraButton>
        </li>
        <li>
          <Link rel="noreferrer" target="_blank" href="https://status.clarifai.com/" className={homePageFooterLink}>
            System Status
          </Link>
        </li>
      </ul>
    </footer>
  );
}
