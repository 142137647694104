import { css } from '@linaria/core';
import { breakpoints, theming } from 'styles/utils';
export const homePageLayoutWrapper = "odules__Home__HomePageLayout_styles_ts-homePageLayoutWrapper-h18gjnog";
export const homePageFooterWrapper = "odules__Home__HomePageLayout_styles_ts-homePageFooterWrapper-hb53gvh";
export const homePageFooterLinks = "odules__Home__HomePageLayout_styles_ts-homePageFooterLinks-h1f7cnvi";
export const homePageFooterLink = "odules__Home__HomePageLayout_styles_ts-homePageFooterLink-h17gi429";
export const configureHomeModal = "odules__Home__HomePageLayout_styles_ts-configureHomeModal-c1xoy2g2";
export const configureHomeTitle = "odules__Home__HomePageLayout_styles_ts-configureHomeTitle-ce1ry4m";
export const configureHomeList = "odules__Home__HomePageLayout_styles_ts-configureHomeList-c63iqn2";
export const configureHomeListItem = "odules__Home__HomePageLayout_styles_ts-configureHomeListItem-c1u8ylva";
export const homeErrorWrapper = "odules__Home__HomePageLayout_styles_ts-homeErrorWrapper-h1tdzw0d";
export const dragHandle = "odules__Home__HomePageLayout_styles_ts-dragHandle-d11etdoq";

require("./HomePageLayout.styles.linaria.module.css!=!../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./HomePageLayout.styles.ts");